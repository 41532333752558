/**
 * The main component of the application.
 */

import React, {Component} from 'react';
import {isMobile} from "react-device-detect";
import {Route, BrowserRouter as Router, Routes} from "react-router-dom";

//This CSS must go above the module imports!
import "bootstrap/dist/css/bootstrap.min.css";
import 'semantic-ui-css/semantic.min.css';

import Welcome from "./Welcome";
import NewStudent from "./NewStudent";
import NewHost from "./NewHost";
import EditDetails from "./EditDetails";
import WeeklyStudent from "./WeeklyStudent";
import WeeklyHost from "./WeeklyHost";
import StudentCancellation from "./StudentCancellation";
import HostCancellation from "./HostCancellation";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import MaintainerView from "./MaintainerView";
import UnknownPage from "./UnknownPage";
import NavBar from "./subcomponents/NavBar";
import {CreateDatabaseLocationList, ExtractCountryCityCommunity, PageLoading, ErrorPage} from "./Util";

//This CSS must go below the module imports!
import './styles/App.css';

export const PATH_HOME = "/"
const PATH_REGISTRATION_STUDENT = "/new/student";
export const PATH_REGISTRATION_HOST = "/new/host";
export const PATH_EDIT_DETAILS = "/edit";
const PATH_WEEKLY_STUDENT = "/student";
export const PATH_WEEKLY_HOST = "/host";
const PATH_CANCELLATION_STUDENT = "/cancel/student"
export const PATH_CANCELLATION_HOST = "/cancel/host"
export const PATH_RESET_PASSWORD = "/resetpassword"
export const PATH_MAINTAINER_UI = "/maintainer"


/**
 * Returns the new student path based on the location details.
 * @param {Object} locationDetails - The details of the location.
 * @param {string} locationDetails.guestType - The type of the guest.
 * @returns {string} The new student path.
 */
export function GetNewStudentPathByLocation(locationDetails)
{
    return PATH_REGISTRATION_STUDENT.replace("student", locationDetails.guestType);
}

/**
 * Returns the weekly student path based on the location details.
 * @param {Object} locationDetails - The details of the location.
 * @param {string} locationDetails.guestType - The type of the guest (e.g., student).
 * @returns {string} The weekly student path.
 */
export function GetWeeklyStudentPathByLocation(locationDetails)
{
    return PATH_WEEKLY_STUDENT.replace("student", locationDetails.guestType);
}

/**
 * Returns the weekly student cancellation path based on the location details.
 * @param {Object} locationDetails - The details of the location.
 * @param {string} locationDetails.guestType - The type of the guest (e.g., student).
 * @returns {string} The weekly student cancellation path.
 */
export function GetWeeklyStudentCancellationPathByLocation(locationDetails)
{
    return PATH_CANCELLATION_STUDENT.replace("student", locationDetails.guestType);
}

export class App extends Component
{
    /**
     * Represents the main component of the application.
     * @param {Object} props - The properties passed to the component.
     */
    constructor(props)
    {
        super(props);

        this.state =
        {
            locationDetails: [],
            loaded: false,
        };
    }

    /**
     * Loads the list of all locations from the database when the site is first loaded.
     * @returns {Promise<void>}
     */
    async componentDidMount()
    {
        let locationList = await CreateDatabaseLocationList();

        this.setState
        ({
            loaded: locationList != null,
            locationDetails: locationList,
        });
    }

    /**
     * Renders the whole site and sets up the routing.
     * @returns {JSX.Element} The whole site.
     */
    render()
    {
        if (!this.state.loaded)
            return PageLoading();

        //Create the routes for each location the site supports
        let key = 0;
        let routes = [];
        let basePaths = {};
        let usedBasePaths = {};
        let allLocationDetails = {};
        for (let details of this.state.locationDetails)
        {
            let {country, city, community} = ExtractCountryCityCommunity(details);
            let basePath = `/${city}-${community}`.toLowerCase(); //URL paths are case-insensitive

            //Build a map of base paths for quick lookup later
            if (!(country in basePaths))
            {
                basePaths[country] = {};
                allLocationDetails[country] = {};
            }
            if (!(city in basePaths[country]))
            {
                basePaths[country][city] = {};
                allLocationDetails[country][city] = {};
            }
            basePaths[country][city][community] = basePath;
            allLocationDetails[country][city][community] = details;

            //Check if this base path is already in use and add to usedBasePaths if not
            if (basePath in usedBasePaths)
                return ErrorPage(<>Dev Error!<br/>Duplicate base path: {basePath}</>); //Should never be reached
            usedBasePaths[basePath] = true;

            //Add the base path to the location details
            details.basePath = basePath;

            //Add the routes for this location
            routes.push(
                <Route key={key++} path={basePath} element={<Welcome basePaths={basePaths} locationDetails={allLocationDetails} country={country} city={city} community={community}/>} exact />,
                <Route key={key++} path={`${basePath}${GetNewStudentPathByLocation(details)}`} element={<NewStudent locationDetails={details}/>} exact />,
                <Route key={key++} path={`${basePath}${GetNewStudentPathByLocation(details)}-couple`} element={<NewStudent couple={true} locationDetails={details}/>} exact />,
                <Route key={key++} path={`${basePath}${PATH_REGISTRATION_HOST}`} element={<NewHost locationDetails={details}/>} exact />,
                <Route key={key++} path={`${basePath}${PATH_EDIT_DETAILS}/:type`} element={<EditDetails locationDetails={details}/>} exact />,
                <Route key={key++} path={`${basePath}${GetWeeklyStudentPathByLocation(details)}`} element={<WeeklyStudent locationDetails={details}/>} exact />,
                <Route key={key++} path={`${basePath}${PATH_WEEKLY_HOST}`} element={<WeeklyHost locationDetails={details}/>} exact />,
                <Route key={key++} path={`${basePath}${GetWeeklyStudentCancellationPathByLocation(details)}`} element={<StudentCancellation locationDetails={details}/>} exact />,
                <Route key={key++} path={`${basePath}${PATH_CANCELLATION_HOST}`} element={<HostCancellation locationDetails={details}/>} exact />,
                <Route key={key++} path={`${basePath}${PATH_MAINTAINER_UI}`} element={<MaintainerView locationDetails={details}/>} exact />,
                <Route key={key++} path={`${basePath}${PATH_RESET_PASSWORD}/:type`} element={<ForgotPassword locationDetails={details}/>} exact />,
                <Route key={key++} path={`${basePath}${PATH_RESET_PASSWORD}/:type/:email/:token`} element={<ResetPassword locationDetails={details}/>} exact />,
            );
        }

        //Add the rest of the routes to the site
        let router =
            <Routes>
                <Route path={PATH_HOME} element={<Welcome basePaths={basePaths} locationDetails={allLocationDetails}/>} exact />
                {routes}
                <Route path="*" element={<UnknownPage/>} />
            </Routes>

        //Render the site
        return (
            <div className={!isMobile ? "app-desktop" : ""}>
                <Router>
                    <div className="app">
                        <NavBar />
                        <div className="app-body">
                            {router}
                            <div className="app-footer">
                                <p>&copy; 2019-{new Date().getFullYear()} Shlabot</p>
                            </div>
                        </div>
                    </div>
                </Router>
            </div>
        );
    }
}

export default App;
