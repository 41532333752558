/**
 * This file defines the WeeklyStudentButton component.
 * It is used for the maintainer to sign a student up for a meal.
 */

import React, {Component} from 'react';
import {Button} from "react-bootstrap";
import Swal from 'sweetalert2'; //Pop-Up
import withReactContent from 'sweetalert2-react-content';

import {DateDropdown} from '../DateDropdown';
import {StudentDropdown} from '../StudentDropdown';
import {StudentGuestSelection, DEFAULT_GUEST_INPUT_FIELDS} from '../StudentGuestSelection';
import {Capitalize, HostsHaveAgeRequest, SendFormToServer, ErrorPopUp} from "../../Util";
import {MAX_FRIENDS, ERROR_MESSAGES as WEEKLY_STUDENT_ERROR_MESSAGES} from "../../WeeklyStudent";

import {MdPersonAdd} from "react-icons/md";

const ReactPopUp = withReactContent(Swal);
const COVID_EXISTS = process.env.COVID_EXISTS != null;
const ERROR_MESSAGES =
{
    ...WEEKLY_STUDENT_ERROR_MESSAGES,
    ALREADY_SIGNED_UP: "{STUDENT} already signed up for that week!",
    ALREADY_SIGNED_UP_TO_HOST: "{STUDENT} already signed up to host that week. They can't join a meal as well!",
    ALREADY_SIGNED_UP_OTHER_COMMUNITY: "{STUDENT} already signed up for a meal that week in the {ERR_COMMUNITY} community!",
}


export class WeeklyStudentButton extends Component
{
    /**
     * Represents the WeeklyStudentButton component.
     * @constructor
     * @param {Object} props - The props object containing the component's properties.
     * @param {string} props.maintainerPassword - The password of the maintainer.
     * @param {Object} props.student - The student to sign up.
     * @param {string} props.customPopUpButtonClassesContinue - The custom class for the pop-up buttons.
     * @param {string} props.customPopUpButtonClassesSuccess - The custom class for the pop-up buttons.
     * @param {Array} props.students - The list of students in the community.
     * @param {Array} props.dateList - The list of dates available to sign up for.
     * @param {Array} props.allergyNameList - The list of allergy names.
     * @param {Array} props.foodPreferenceNameList - The list of food preference names.
     * @param {Object} props.locationDetails - The location details for the current community.
     * @param {Function} props.addParentWeeklySignUp - The function to add a sign-up to the parent component.
     */
    constructor(props)
    {
        super(props);

        this.state =
        {
            dateInput: "",
            holyDayInput: "",
            mealInput: "",
            friendsInput: [],
            signUpCouple: false,
            ...DEFAULT_GUEST_INPUT_FIELDS,
            studentList: props.students,
            dateList: props.dateList,
            allergyNameList: props.allergyNameList,
            foodPreferenceNameList: props.foodPreferenceNameList,
            maintainerPassword: props.maintainerPassword,
            student: props.student,
            customPopUpButtonClassesContinue: props.customPopUpButtonClassesContinue,
            customPopUpButtonClassesSuccess: props.customPopUpButtonClassesSuccess,
            locationDetails: props.locationDetails,
        };

        this.addParentWeeklySignUp = props.addParentWeeklySignUp;
    }

    /**
     * Returns the full name of the student to be signed-up.
     * @returns {string} The first and last name of the student.
     */
    getStudentName()
    {
        return `${this.state.student.firstName} ${this.state.student.lastName}`;
    }

    /**
     * Checks if the student is bringing guests.
     * @returns {boolean} Whether the student is bringing guests.
     */
    bringingGuests()
    {
        return this.state.maleGuestsInput > 0 || this.state.femaleGuestsInput > 0;
    }

    /**
     * Sets the date inputs for the form.
     * @param {string} date - The string date to set.
     * @param {string} holyDay - The holy day to set.
     * @param {string} meal - The meal to set.
     */
    setDateInputs(date, holyDay, meal)
    {
        this.setState({dateInput: date, holyDayInput: holyDay, mealInput: meal});
    }

    /**
     * Sets the selected friends for the student.
     * @param {Array} friends - The array of friends to set.
     */
    setFriendsInput(friends)
    {
        this.setState({friendsInput: friends});
    }

    /**
     * Sets the input values for guest selection.
     * @param {Object} updatedValues - The updated values to set in the state.
     * @param {number} updatedValues.maleGuestsInput - The number of male guests.
     * @param {number} updatedValues.femaleGuestsInput - The number of female guests.
     * @param {string[]} updatedValues.guestAllergiesInput - The allergies of the guests.
     * @param {string[]} updatedValues.guestFoodPreferenceInput - The food preferences of the guests.
     * @param {number} updatedValues.guestMinAgeInput - The minimum age of the guests.
     * @param {number} updatedValues.guestMaxAgeInput - The maximum age of the guests.
     * @param {boolean} updatedValues.guestVaccinatedInput - Indicates if the guests are vaccinated.
     * @param {string[]} updatedValues.allergyNameList - The updated list of allergy names.
     * @param {string[]} updatedValues.foodPreferenceNameList - The updated list of food preference names.
     */
    setGuestsInput(updatedValues)
    {
        this.setState(updatedValues);
    }

    /**
     * Handles the date selection for signing up a student.
     * @returns {Promise<boolean>} Whether to continue with the sign-up process or not.
     */
    async handleDateSelection()
    {
        let result = await ReactPopUp.fire
        ({
            title: `Sign ${this.getStudentName()} up for which date?`,
            html:
                <DateDropdown dateList={this.state.dateList} dateInput={this.state.dateInput}
                    holyDayInput={this.state.holyDayInput} mealInput={this.state.mealInput}
                    setParentDateInputs={this.setDateInputs.bind(this)} />,
            showCancelButton: true,
            confirmButtonText: `Continue`,
            buttonsStyling: false,
            customClass: this.state.customPopUpButtonClassesContinue,

            //If the date is not selected, show a validation error in the pop-up
            preConfirm: () =>
            {
                if (!this.state.dateInput)
                {
                    ReactPopUp.showValidationMessage("Please select a date.");
                    return false;
                }
            },
        });

        return result.isConfirmed;
    }

    /**
     * Handles the selection of friends for the student.
     * @returns {Promise<boolean>} Whether to continue with the sign-up process or not.
     */
    async handleFriendsSelection()
    {
        let result = await ReactPopUp.fire
        ({
            title: `Does ${this.getStudentName()} want to go with any friends?`,
            html:
                <StudentDropdown
                    students={this.state.friendsInput}
                    filteredStudents={[this.state.student.email]} //Filter out the student themselves
                    maxAllowed={MAX_FRIENDS}
                    setParentStudents={this.setFriendsInput.bind(this)}
                    locationDetails={this.state.locationDetails} />,
            showCancelButton: true,
            confirmButtonText: `Continue`,
            buttonsStyling: false,
            customClass: this.state.customPopUpButtonClassesContinue,
        });

        return result.isConfirmed;
    }

    /**
     * Handles the selection of guests for a student.
     * @returns {Promise<boolean>} Whether to continue with the sign-up process or not.
     */
    async handleGuestsSelection()
    {
        let result = await ReactPopUp.fire
        ({
            title: `Is ${this.getStudentName()} bringing any guests?`,
            html:
                <StudentGuestSelection
                    maintainer={true}
                    studentName={this.getStudentName()}
                    allergyNameList={this.state.allergyNameList}
                    foodPreferenceNameList={this.state.foodPreferenceNameList}
                    setParentGuestDetails={this.setGuestsInput.bind(this)}
                    locationDetails={this.state.locationDetails} />,
            showCancelButton: true,
            confirmButtonText: `Continue`,
            buttonsStyling: false,
            customClass: this.state.customPopUpButtonClassesContinue,

            //If the age range is not selected, show a validation error in the pop-up
            preConfirm: () =>
            {
                if (this.bringingGuests()
                && HostsHaveAgeRequest(this.state.locationDetails)
                && (!this.state.guestMinAgeInput || !this.state.guestMaxAgeInput))
                {
                    ReactPopUp.showValidationMessage("Please set the guest age range.");
                    return false;
                }
            },
        });

        return result.isConfirmed;
    }

    /**
     * Handles the couple selection process.
     * If the student is not dating, it is skipped.
     * Otherwise, it prompts a confirmation dialog to ask if the student is signing up with their dating partner.
     * @returns {Promise<boolean>} Whether to continue with the sign-up process or not.
     */
    //TODO: For now the maintainer can sign both up one at a time. Haven't figured out the best way to reload the daashboard data after both are signed up.
    //Also clicking no doesn't continue in the process like it should.
    /*async handleCoupleSelection()
    {
        if (this.state.student.dating === "")
            return true; //No need to ask if the student is not dating

        let datingStudentEmail = this.state.student.dating;
        let datingStudent = this.state.studentList.find(student => student.email === datingStudentEmail);
        let datingStudentName = (datingStudent) ? `${datingStudent.firstName} ${datingStudent.lastName}` : datingStudentEmail;

        let result = await ReactPopUp.fire
        ({
            title: `Is ${this.getStudentName()} signing up with ${datingStudentName}?`,
            showCancelButton: true,
            confirmButtonText: `Yes`,
            cancelButtonText: `No`,
            buttonsStyling: false,
            customClass: this.state.customPopUpButtonClassesContinue,
        });

        this.setState({signUpCouple: result.isConfirmed});
        return result.isConfirmed || result.isDenied;
    }*/

    /**
     * Finalizes the sign-up process for a student.
     */
    async finalizeSignUp()
    {
        const titleClass = "fw-bold";
        const name = this.getStudentName();
        const date = `${this.state.dateInput} - ${this.state.holyDayInput} ${Capitalize(this.state.mealInput)}`;
        const maleGuests = this.state.maleGuestsInput;
        const femaleGuests = this.state.femaleGuestsInput;
        const ageRange = `${this.state.guestMinAgeInput} - ${this.state.guestMaxAgeInput}`;
        const allergies = this.state.guestAllergiesInput.join(", ");
        const foodPrefs = this.state.guestFoodPreferenceInput.join(", ");
        const vaccinated = this.state.guestVaccinatedInput ? "Yes" : "No";

        //Replace each friend email with their full name for visual confirmation
        let friends = this.state.friendsInput.map((friend) =>
        {
            let student = this.state.studentList.find((student) => student.email === friend);
            return student ? `${student.firstName} ${student.lastName}` : friend; //Display the email by default
        });
        friends = friends.join(", ");

        await ReactPopUp.fire
        ({
            title: "Confirm Sign-Up",
            html:
                <div className="text-start">
                    <p><span className={titleClass}>Name:</span> {name}</p>
                    <p><span className={titleClass}>Date:</span> {date}</p>
                    {
                        friends.length > 0 &&
                            <p><span className={titleClass}>Friends:</span> {friends}</p>
                    }
                    {
                        (this.bringingGuests()) &&
                        <>
                            <p><span className={titleClass}>Male Guests:</span> {maleGuests}</p>
                            <p><span className={titleClass}>Female Guests:</span> {femaleGuests}</p>
                            {
                                (this.state.guestMinAgeInput || this.state.guestMaxAgeInput) &&
                                    <p><span className={titleClass}>Guest Age Range:</span> {ageRange}</p>
                            }
                            {
                                this.state.guestAllergiesInput.length > 0 &&
                                    <p><span className={titleClass}>Guest Allergies:</span> {allergies}</p>
                            }
                            {
                                this.state.guestFoodPreferenceInput.length > 0 &&
                                    <p><span className={titleClass}>Guest Food Preferences:</span> {foodPrefs}</p>
                            }
                            {
                                COVID_EXISTS && this.state.guestVaccinatedInput !== "" &&
                                    <p><span className={titleClass}>Guest Vaccinated:</span> {vaccinated}</p>
                            }
                        </>
                    }
                </div>,
            showCancelButton: true,
            confirmButtonText: `Sign Up`,
            buttonsStyling: false,
            customClass: this.state.customPopUpButtonClassesSuccess,
        }).then(async (result) =>
        {
            if (result.isConfirmed)
                await this.signUpStudent();
        });
    }

    /**
     * Signs up a student by sending form data to the server.
     */
    async signUpStudent()
    {
        const route = "/maintainerweeklystudentsignup";
        const data =
        {
            //If updating this data, potentially also update newDashboardData below
            email: this.state.student.email,
            password: this.state.maintainerPassword,
            date: this.state.dateInput,
            type: this.state.holyDayInput,
            meal: this.state.mealInput,
            friends: this.state.friendsInput,
            signUpCouple: this.state.signUpCouple,
            maleGuests: Number(this.state.maleGuestsInput),
            femaleGuests: Number(this.state.femaleGuestsInput),
            guestAllergies: this.bringingGuests() ? this.state.guestAllergiesInput : [],
            guestFoodPrefs: this.bringingGuests() ? this.state.guestFoodPreferenceInput : [],
            guestMinAge: (this.state.guestMinAgeInput === "") ? 0 : this.bringingGuests() ? Number(this.state.guestMinAgeInput) : 0,
            guestMaxAge: (this.state.guestMaxAgeInput === "") ? 0 : this.bringingGuests() ? Number(this.state.guestMaxAgeInput) : 0,
            guestVaccinated: this.bringingGuests() ? this.state.guestVaccinatedInput : true, //Only relevant if guests are brought
        };

        await SendFormToServer(data, this, route, `${this.getStudentName()} has been signed up!`, this.state.locationDetails, null);

        //Loop while the formRetVal is null and at most for 30 seconds
        while (this.state.formRetVal == null)
        {
            let time = new Date().getTime();
            await new Promise(resolve => setTimeout(resolve, 100));
            if (new Date().getTime() - time > 30000)
                break;
        }

        //If the command executed successfully, add the sign-up to the dashboard data
        if (this.state.formRetVal && !this.state.showedErrorPopUp)
        {
            const newDashboardData =
            {
                email: data.email,
                name: this.getStudentName(),
                maleGuests: data.maleGuests,
                femaleGuests: data.femaleGuests,
                guestMinAge: data.guestMinAge,
                guestMaxAge: data.guestMaxAge,
                guestAllergies: data.guestAllergies.concat(data.guestFoodPrefs),
            };

            this.addParentWeeklySignUp(this.state.dateInput, this.state.mealInput, newDashboardData);
        }
    }

    /**
     * Handles the sign-up process for the weekly student button.
     */
    async handleSignUp()
    {
        //Restore the original state
        this.setState(
        {
            dateInput: "",
            holyDayInput: "",
            mealInput: "",
            friendsInput: [],
            ...DEFAULT_GUEST_INPUT_FIELDS,
            signUpCouple: false,
        }, async () =>
        {
            //Choose the date
            if (!(await this.handleDateSelection()))
                return;
            if (!this.state.dateInput)
                return;

            //Choose friends
            if (!(await this.handleFriendsSelection()))
                return;

            //Choose guest details
            if (!(await this.handleGuestsSelection()))
                return;

            //Choose couple sign-up
            /*if (!(await this.handleCoupleSelection()))
                return;*/

            //Finalize the sign-up
            if (!(await this.finalizeSignUp()))
                return;
        });
    }

    /**
     * Displays an error pop-up.
     * @param {string} errorSymbol - The error symbol for the message to be shown on the pop-up.
     */
    errorPopUp(errorSymbol)
    {
        let text = (errorSymbol in ERROR_MESSAGES) ?  ERROR_MESSAGES[errorSymbol] : errorSymbol;
        text = text.replaceAll("{STUDENT}", this.getStudentName());
        text = text.replaceAll("{GUEST_TYPE}", this.state.locationDetails.guestType);
        text = text.replaceAll("{ERR_COMMUNITY}", this.state.errorMsgCommunity);
        this.setState({showedErrorPopUp: true});
        ErrorPopUp(text);
    }

    /**
     * Renders the WeeklyStudentButton component.
     * @returns {JSX.Element} The rendered WeeklyStudentButton component.
     */
    render()
    {
        return (
            <Button className="maintainer-weekly-sign-up-button"
                    onClick={this.handleSignUp.bind(this)}>
                <MdPersonAdd size={20} />
            </Button>
        );
    }
}

export default WeeklyStudentButton;
