/**
 * This file defines the PhoneNumberField component.
 * It is used to input a phone number with a country code and show the flag emoji of the country code.
 */

import React, {Component} from 'react';
import {Form} from "react-bootstrap";
import PhoneInput from 'react-phone-number-input';
import {getCountries, getCountryCallingCode, formatPhoneNumberIntl, isValidPhoneNumber} from 'react-phone-number-input/input';
import en from 'react-phone-number-input/locale/en';

import {RequiredTooltip} from '../Util';
import CountryCodes from "../data/CountryCodes.json";

import 'react-phone-number-input/style.css';
import "../styles/PhoneNumberField.css";


export class PhoneNumberField extends Component
{
    /**
     * Represents the PhoneNumberField component.
     * @constructor
     * @param {Object} props - The props object containing the component's properties.
     * @param {string} props.currentCountry - The current country's country code.
     * @param {string} props.phoneNumber - The default phone number input value.
     * @param {Function} props.setParentPhoneNumber - The function to set the parent's phone number.
     * @param {Function} props.validPhoneNumber - The function to check if the phone number is valid.
     * @param {Function} props.isErrorMessage - The function to check if there is a specific error message.
     * @param {string} props.idMod - The string to modify the id of the phone number input.
     */
    constructor(props)
    {
        super(props);

        this.state =
        {
            currentCountryCode: this.countryToCode(props.currentCountry),
            phoneNumber: props.phoneNumber,
            idMod: props.idMod || "",
        }

        this.setParentPhoneNumber = props.setParentPhoneNumber;
        this.validPhoneNumber = props.validPhoneNumber;
        this.isErrorMessage = props.isErrorMessage;
    }

    /**
     * Returns the country code for the given country.
     * @param {string} country - The name of the country.
     * @returns {string} The country code.
     */
    countryToCode(country)
    {
        return CountryCodes.countryCodeByCountryName[country];
    }

    /**
     * Sets the phone number and also updates the parent component.
     * @param {string} phoneNumber - The phone number to set.
     */
    setPhoneNumber(phoneNumber)
    {
        this.setState({phoneNumber});
        this.setParentPhoneNumber(phoneNumber);
    }

    /**
     * Renders the PhoneNumberField component.
     * @returns {JSX.Element} The rendered PhoneNumberField component.
     */
    render()
    {
        const required = RequiredTooltip();

        // Create a new labels object with the country calling codes
        const labels = structuredClone(en);
        getCountries().forEach((country) =>
        {
            labels[country] = `${en[country]} (+${getCountryCallingCode(country)})`;
        });

        return (
            <Form.Group className="mb-3" controlId={"formPhoneNumber" + this.state.idMod} >
                <Form.Label>WhatsApp or Phone Number{required}</Form.Label>
                <PhoneInput
                    international
                    defaultCountry={this.state.currentCountryCode}
                    value={this.state.phoneNumber}
                    onChange={this.setPhoneNumber.bind(this)}
                    id={"formPhoneNumberInput" + this.state.idMod}
                    className={`${(this.state.phoneNumber && !this.validPhoneNumber(this.state.phoneNumber)) ? 'is-invalid' : ''}`}
                    data-testid={"phone-number-input" + this.state.idMod}
                    labels={labels}
                />
            </Form.Group>
        );
    }
}

/**
 * Validates a phone number.
 * @param {string} phoneNumber - The phone number to validate.
 * @returns {boolean} - Whether the phone number is valid.
 */
export function ValidatePhoneNumber(phoneNumber)
{
    return isValidPhoneNumber(phoneNumber || "");
}

/**
 * Formats a phone number for storage in the database.
 * @param {string} phoneNumber - The phone number to be formatted.
 * @returns {string} The formatted phone number.
 */
export function PrettyPrintPhoneNumber(phoneNumber)
{
    phoneNumber = formatPhoneNumberIntl(phoneNumber);

    //Replace all spaces except for the first with dashes
    return phoneNumber.replace(/ /g, "-").replace(/-/, " ");
}

/**
 * Generates a WhatsApp link for a given phone number.
 * @param {string} phoneNumber - The phone number to generate the link for.
 * @returns {JSX.Element} - The component that, when clicked, opens the WhatsApp page in a new tab.
 */
export function WhatsAppLink(phoneNumber)
{
    //Replace all spaces, dash, and + with nothing
    const url = "https://wa.me/" + phoneNumber.replace(/[\s\-+]/g, "");

    //Return component that when clicked opens the page in a new tab
    return (
        <a href={url} target="_blank" rel="noreferrer">
            {phoneNumber}
        </a>
    );
}

export default PhoneNumberField;
